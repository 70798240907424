

import { Nav } from "./header";
import Footer from "./footer";
import { Fade } from "react-reveal";
export default function Qus(){
 return (
 <>
    <Nav activeone = "active"></Nav>
    <div className="osual-faq-container">
        <div className="osual-faq-content">
            <h3 className="osual-faq-tag">البيع مع أصول </h3>
            <h1 className="osual-faq-title">أسألة متكرّرة</h1>
            <p className="osual-faq-text">إليك بعض الأسئلة الشائعة والأكثر تكرّراً حول كيفيّة بيع  أصولك مع أصول ! يمكنك أيضًا التواصل معنا إذا كانت لديك أي أسئلة وسيقوم فريقنا بالاعتناء بك!</p>
            <a href="@#" className="osual-faq-btn">اسأل  فريقنا</a>
        </div>
        <div className="osual-faq">
        <FAQBOX title = "هل بإمكان أي شخص الإعلان في أصول ؟" text = "نعم ! في أصول يمكن أن يعلن فقط أي سعودي، سواء مالك أو مسوق، ويشترط أن يحمل رخصة مسوق عقاري ، أو صك للعقار المعلن عنه ."/>
        <FAQBOX title = "ما هي تكلفة الاعلان باستخدام أصول ؟" uniq = "الإعلان في أصول مجانًا" text = "، يمكنك الإعلان في أي وقت ! وهناك عمولة تُفرض على المعلن بعد بيع العقار ."/>
        <FAQBOX title = "كم نسبة عمولة أصول ؟" textbefor ="عمولة أصول هي" uniq = " 2.5%  من قيمة السعي فقط" text = ". مثلاً عقار قيمتة 1 مليون ريال، عمولة المسوق 25 ألف ريال، تصبح عمولة أصول 625 ريال ."/>
        <FAQBOX title = "هل يمكن للمطور العقاري،  إدراج مشاريعة في أصول ؟" text ="نعم، يـمكن لأي مطور الانضمام إلى أصول، بشرط أن يكون مرخص من قبل الهيئة العامة للعقار ."/>
        <FAQBOX title = "ما هي تكلفة إدراج مشروعي العقاري في أصول ؟" text = "تختلف التكلفة من مطور لآخر، بحسب نوع المشروع، وحجم المشروع ."/>
        <FAQBOX title = "هل أصول العقارية مرخصة من قبل الهيئة العامة للعقار ؟" text = "نعم، أصول العقارية مرخصة من قبل الهيئة العامة للعقار، بتاريخ 1445/2/12 هـ، برخصة رقم 112376897 ."/>
        </div>
    </div>
    <div className="AboutUs-CV">
    <div className="AboutUs-CV-container">
        <h1 className="AboutUs-CV-title">هل أنت مستعدّ لبيع عقارك؟</h1>
        <h6 className="AboutUs-CV-text">إذا كنت تفكّر في البيع فلا تتردّد! الإجراءات سريعة وغير مُلزمةالبيع مع أصول</h6>
        <a href="" className="AboutUs-CV-btn">تواصل معنا</a>
    </div>
    </div>
    <Footer></Footer>
    </>
    )
}

export function FAQBOX(props) {
    function switchText(e) {
        e.target.className  == "fa-solid fa-plus" ? e.target.className = "fa-solid fa-minus" : e.target.className = "fa-solid fa-plus"
        const textEle = e.target.parentElement.parentElement.lastChild
        if(e.target.className  == "fa-solid fa-minus"){
            textEle.style.display ="block"
            textEle.style.animation ="FadeText 0.4s ease-in" 
            textEle.style.animationFillMode ="both" 
        }else if(e.target.className  == "fa-solid fa-plus"){
            textEle.style.display ="none"
            textEle.style.animation ="FadeTextOut 0.4s ease-in" 
            textEle.style.animationFillMode ="both"    
        }
    }
    return (
        <>
        <div className="FAQBox">
            <div className="FAQBox-header">
                <i class="fa-solid fa-plus" onClick={switchText}></i>
                <h1 className="FAQBox-title" dir="rtl">{props.title}</h1>
            </div>
            <p className="FAQBox-text" dir="rtl">{props.textbefor}<span>{props.uniq}</span>{props.text}</p>
        </div>
        </>
    )
}