import { Nav } from "./header";
import Footer from "./footer";
import { Fade } from "react-reveal";

export default function AboutUs(props) {
    return (
    <>
    <Nav activetwo ="active"></Nav>
    <Fade>
    <div className="AboutUs-header about-header-container">
        <Fade top>
        <div className="AboutUs-content">
            <h3 className="AboutUs-header-tag">البيع مع أصول </h3>
            <h1 className="AboutUs-header-title">بيع العقار أكثر سهولة مع أصول العقاريّة</h1>
            <h5 className="AboutUs-header-text">يعرض لدينا اعلانات أفضل العقاريين في المملكة، بدءا من المسوقين الموثقين عبر أبشر، وأصول الملاك .</h5>
            <a href="#" className="AboutUs-btn">البيع مع أصول</a>
        </div>
        </Fade>
        <Fade bottom>
        <div className="AboutUs-partners">
            <p className="partners-text">كما نعمل مع</p>
            <div className="partners">
                <img src="./images/partners.png" alt="partner logo" width={"146.5px"} height={"83.932px"} />
                <img src="./images/partners.png" alt="partner logo" width={"146.5px"} height={"83.932px"} />
                <img src="./images/partners.png" alt="partner logo" width={"146.5px"} height={"83.932px"} />
                <img src="./images/partners.png" alt="partner logo" width={"146.5px"} height={"83.932px"} />
            </div>
        </div>
        </Fade>
    </div>
    </Fade>
    <div className="AboutUs-dron">
        <Fade right>
        <div className="dron-content">
            <h1 className="dron-title">تغطية عقارية حديثة، للمطورين ..</h1>
            <p className="dron-text">يتمتع فريقنا بخبرة متقدمة في عرض المنتجات العقارية، <br /> بطريقة جديدة كليا في السوق العقاري، يتم تغطيـة وتصوير منتجك بأعلى المعايير</p>
            <a  href="#" className="AboutUs-btn">البيع مع أصول</a>
        </div>
        </Fade>
            <Fade left>
        <div className="osual-dron">
            <img src="./images/osual-dron.png" alt="Drone" />
        </div>
            </Fade>
    </div>
    <div className="AboutUs-cards">
        <div className="AboutUs-cards-content">
            <h1 className="AboutUs-title">طريقة العمل</h1>
            <p className="AboutUs-text">قم بإدراج عقارك على أصول، واحصل على العربون فورًا عند دفع العميل. بكل سهولة</p>
        </div>
        <div className="AboutUs-cards-container">
            <Fade bottom >
            <Card icon = "./images/icons/quick-offer 1.png" title = "الاتفاق مع أصول العقارية" text = "فور التواصل معنا، والاتفاق، على العقود المبرمة, يتم التأكيد من تحقيق الشروط والأحكام، الخاصة بالهيئة العامة للعقار ." />
            </Fade>
            <Fade bottom delay = {300}>
            <Card icon = "./images/icons/due-diligence 1.png" title = "اضافة مشروعك العقاري" text = "بعد موافقة كلا الأطراف، يتم البـدء في اضافة المشروع العقاري، بكامل التفاصيل, بعد استلام مستندات الوحدات العقارية من قبلكم ." />
            </Fade>
            <Fade bottom delay = {600}>
            <Card icon = "./images/icons/user 1.png" title = "احصل على عملاء موثوقين" text = "أثناء عرض المشروع بشكل رائع، يتـم جذب العملاء المهتمين بالوحدات الخاصة بهذا المشروع، والتواصل مع المعلن - المطوّر - ." />
            </Fade>
            <Fade bottom delay = {900}>
            <Card icon = "./images/icons/easy-close 1.png" title = "تلقى العربون بشكل موثوق" text = "أخيرا، مع أصول العقارية - يتم دفع العربون  داخل التطبيق، من قبل العملاء المهتمين ." />
            </Fade>
        </div>
    </div>
    <div className="AboutUs-features">
        <div className="AboutUs-features-content">
            <h1 className="AboutUs-features-title">لماذا تقوم بالبيع مع أصول ؟</h1>
            <p className="AboutUs-features-text">قم ببيع عقارك نقداً. لدى أصول معدّل إغلاق بنسبة 100٪ ومستعدّين أن نقدّم لك عملاء مهتمّين بشدّة، دون أيّ متاعب</p>
        </div>
        <div className="AboutUs-features-card">
            <Fade right>
            <FeaturesCard img = "./images/icons/cashPayicon.png" title = "ضمان العميل بنسبة 90٪، بعد دفع العربون" text = "ستتلقى غالبية المنازل المدرجة عرضًا تنافسيًا في غضون أيام، ونهدف إلى الحفاظ على معدّل ضمان البيع بنسبة 100٪ على منصّتنا" />
            </Fade>
            <Fade left delay ={200}>
            <FeaturesCard img = "./images/icons/aprv.png" title = "تخارج خال من المتاعب مع رسوم منخفضة" text = "تعتبر أصول العقاريـة، الأقل رسومًا بين المنافسين في سوق المملكة العربية السعودية، تحقيقا لأهداف رؤية المملكة 2030، بتعزيز نمـو القطاع العقاري" />
            </Fade>
            <Fade bottom delay ={400}>
            <FeaturesCard img = "./images/icons/data.png" title = "بيع في الجدول الزمني الخاص بك" text = "نقدّم خانة خاصة لجدولة زيارات العملاء لمشروعك، ولكن عليك تحديد الأوقات المتاحة للبيع، لضمان المرونة أثناء عملية البيع ." />
            </Fade>
            <Fade bottom delay ={600}>
            <FeaturesCard img = "./images/icons/dron.png" title = "تصوير جوي، وجولة افتراضية" text = "يتم تغطية العقار، عبر طيارة درونز مرخّصة من هيئة الطيران المدني، وجولة افتراضية 360 درجة، كل ذلك مجانا، بعد اضافة مشروعك العقاري في أصول العقارية ." />
            </Fade>
        </div>
    </div>
    <div className="AboutUs-CV">
    <div className="AboutUs-CV-container">
        <h1 className="AboutUs-CV-title">هل أنت مستعدّ لإدراج مشروعك العقاري ؟</h1>
        <h6 className="AboutUs-CV-text">إذا كنت تفكّر في البيع فلا تتردّد! الإجراءات سريعة وغير مُلزمةالبيع مع أصول</h6>
        <a href="" className="AboutUs-CV-btn">تواصل معنا</a>
    </div>
    </div>
    <Footer/>
    </>
    )
}



export function Card(props) {
  return  <>
    <div className="AboutUs-card">
        <img className="AboutUs-card-img" src={props.icon}></img>
        <h1 className="AboutUs-card-title">{props.title}</h1>
        <p className="AboutUs-card-text">{props.text}</p>
    </div>
    </>
}

export function FeaturesCard(props) {
    return <>
    <div className="FeaturesCard">
        <img className="FeaturesCard-icon" src={props.img}></img>
        <div className="FeaturesCard-content">
            <h1 className="FeaturesCard-title">{props.title}</h1>
            <p className="FeaturesCard-text">{props.text}</p>
        </div>
    </div>
    </>
}
export function CV() {
    <div className="AboutUs-CV">
    <div className="AboutUs-CV-container">
        <h1 className="AboutUs-CV-title">هل أنت مستعدّ لبيع عقارك؟</h1>
        <h6 className="AboutUs-CV-text">إذا كنت تفكّر في البيع فلا تتردّد! الإجراءات سريعة وغير مُلزمةالبيع مع أصول</h6>
        <a href="" className="AboutUs-CV-btn">ارسل السيرة الذاتية </a>
    </div>
    </div>
}