import React from 'react';
import Main from './screens/Main';
import About from './screens/about';
import {Developers} from './screens/developers';
import AboutUs from './screens/AboutUs';
import Qus from './screens/Qus';
import Admine from './screens/Admine';
import DashBoard from './screens/dashboard';
import "./css/mainPluse.css" ;
import "./css/utilityPluse.css" ; 
import "./css/aboutPluse.css"
import "./css/developersPluse.css"
import "./css/AboutUsPluse.css"
import './css/faqPluse.css'
import './css/admine.css'
import { Routes, Route ,HashRouter} from "react-router-dom"
function App() {
  return <><HashRouter>
      <Routes>  
    <Route path='/' element = {<Main/>}/>
    <Route path='about' element = {<About/>}/>
    <Route path='Developers' element = {<Developers/>}/>
    <Route path='aboutUs' element = {<AboutUs/>}/>
    <Route path='qus' element = {<Qus/>}/>
    <Route path='admine' element = {<Admine/>}/>
    <Route path='dashboard' element = {<DashBoard/>}/>
  </Routes>
  </HashRouter>
  </>
}

export default App;


