import React from "react";
import { Fade } from "react-reveal";
import ProjectCard from "./developers"

export default function Cards(){
    return (
        <>
        <div className="cards">
            <div className="cards-header">
                <h1 className="cards-header-title">فئات معلنين أصول</h1>
                <p className="cards-header-text">منصّتنا متاحة لمُطورين وملاّك ومسوّقين الأصول، في جميع أنحاء المملكة</p>
            </div>
            <div className="main-cards">
                <Fade bottom >
                <WhiteCard src = "./images/icons/cashPayicon.png" title = "مالك أصول" text = "يتم الاعلان من قبل مالك الأصل, وذلك بتأكيد رقم الصك, والتحقق من الهويـة عبر أبشر ."/>
                </Fade>
                <Fade>
                    <div className="test">
                        <ProjectCard backgroundImage = "./images/veli.png" icon = "./images/icons/ninjaGreen.png" devname = "مشروع الأصالة الفارهة " percentage = "50٪ مُباع" price = "40,000,000 ر.س" devicon =  "./images/ousal-logo.png" name = "أصول العقارية"/>
                        <div className="ousol-dev">
                            <p>مطوّر أصول</p>
                            <img src="./images/icons/user-icon.png" alt = "user icon" />
                        </div>
                    </div>
                </Fade>
                <Fade bottom >
                <WhiteCard src = "./images/icons/cashflow.png" title = "مسوّق أصول" text = "يتم الاعلان من قبل مسوق عقاري معتمد من قبل الهيئة العامة للعقار, وبتوثيق الرخصة عبر أبشر"/>
                </Fade>
            </div>
        </div>
        </>
    )
}

export function WhiteCard(props) {
    return (
        <>
        <div className="white-card">
           <img src= {props.src}  alt="logo" className="white-card-icon" />
           <div className="white-card-contant">
            <div className="white-card-title">{props.title}</div>
            <div className="white-card-text">{props.text}</div>
           </div>
        </div>
        </>
    )
}
// export function ProjCard(props) {
//     return (
//         <>
//         <div className="proj-card">
//             <div className="proj-card-nav">
//                 <img src = {props.icon} alt = "ninja icon" className="proj-card-logo" />
//                 <h3 className="proj-card-type">{props.tag}</h3>
//             </div>
//             <img src= "./images/projectimg.png" alt = "house" className="proj-card-img" />
//             <div className="proj-card-body">
//                 <div className="proj-card-contant">
//                     <h1 className="proj-card-title">{props.title}</h1>
//                     <p className="proj-card-text">{props.description}</p>
//                 </div>
//                 <div className="proj-card-numbers">
//                     <h1 className="proj-card-number">{props.number}</h1>
//                     <span className="proj-card-bar"></span>
//                     <h1 className="proj-card-price">{props.price}</h1>
//                 </div>
//                 <div className="proj-card-stats">
//                     <State number = "4" type = "غرف لكل وحدة" />
//                     <State number = "450m" type = "حجم المساحة" />
//                     <State number = "10" type = "عدد القطع" />
//                 </div>
//                 <div className="proj-card-prof">
//                     <h2 className="prof-name"><span>النوع</span> {props.type}</h2>
//                     <div className="prof-avtar">
//                         <h2 className="prof-name"><span>المطور</span> {props.name}</h2>
//                         <img src = {props.avtar}  alt = "avtar" className="prof-img"/>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         </>
//     )
// }

export function State(props){
    return (
        <>
        <div className="stat">
            <h1 className="stat-number">{props.number}</h1>
            <p className="stat-type">{props.type}</p>
        </div>
        </>
    )
}