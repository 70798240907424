// import React from "react";
import { Fade } from "react-reveal";
import React,{useRef, useState} from "react";

export default function Bounty() {
    const [message, setMessage] = useState(6);
    const handleChange = event => {
        setMessage(event.target.value);
        event.target.style.backgroundSize = (event.target.value - event.target.min) * 100 / (event.target.max - event.target.min) + '% 100%'
      };
    return (
        <>
        <div className="bounty">
            <div className="bounty-card">
                <Fade bottom>
                <div className="bounty-header">
                    <div className="bounty-card-contant">
                        <p className="bounty-card-text">عندما عقار  بـ</p>
                        <h1 className="bounty-card-title">أو اكثر 1,000,000 ر.س</h1>
                    </div>
                    <img src="./images/Frame-logo.png" alt = "logo"   className="bounty-logo"/>
                </div>
                </Fade>
                <Fade bottom delay = {200}>
                <ul className="bounty-list">
                    <li className="bounty-list-item">
                        <h3 className="bounty-item-text">
                        <span>ريال سعودي مقابل كل دعوة مؤهلة</span>
                        <span dir="ltr"> 50 </span>
                        </h3>
                    <i class="fa-solid fa-check" style={ {fontSize : "16px"  , color : "#4C4DDC"}} ></i>
                    </li>
                    <li className="bounty-list-item">
                    <h3 className="bounty-item-text">
                        <span>استرداد نقدي على كل استثمار</span>
                        <span dir="ltr"> 1% </span>
                    </h3>
                    <i class="fa-solid fa-check" style={ {fontSize : "16px" , color : "#4C4DDC"}} ></i>
                    </li>
                    <li className="bounty-list-item">
                    <h3 className="bounty-item-text" style={{color: "#A3ABBA"}}>هديّة ترحيبية</h3>
                    <i class="fa-solid fa-xmark" style={ {fontSize : "16px" , color : "#6B7284"}}></i>
                    </li>
                </ul>
                </Fade>
            </div>
            <div className="bounty-right">
                <div className="bounty-contant">
                    <Fade bottom>
                    <h1 className="bounty-title">احصل على مكافآت بأعلانك فـي أصُـول</h1>
                    </Fade>
                    <Fade bottom delay = {200}>
                    <p className="bounty-text">كلّما أعلنت أكثر، تحصل على المزيد من المزايا ! يتم إدراج جميع المستخدمين تلقائيًا في فآت العضوية لدينا، والمكافآت تبدأ من اغلاق أول صفقة</p>
                    </Fade>
                </div>
                <div className="bounty-footer">
                    <div className="bounty-footer-header">
                        <h1 className="bounty-footer-number">
                        عقارات
                        <span > {message} </span>
                        </h1>
                        <p className="bounty-footer-text">ما هو قيمة العقارات التي ترغب في بيعها ؟</p>
                    </div>
                    {/* <span className="bounty-footer-range"></span> */}
                    <input  id="range-one" min = {"2"} max = {"10"} step="1" /*defaultValue = {5}*/  onChange={handleChange}  type={"range"} className="range-bar"></input>

                </div>
            </div>
        </div>
        </>
    )
}