import React,{useEffect, useState} from "react";
import { Nav } from "./header";
import Footer from "./footer";
import { Projimges } from "./Projects";
import { Fade } from "react-reveal";
export  function Developers () {
    const [toggleProject ,toggleProjectFunc]  = useState(false)
    const ProjectsPageOne = {
        icon : "./images/icons/ninjaGreen.png" ,
        name : "مشروع اُنس العقارية ",
        parent : "50٪ مُباع",
        price : "40,000,000 ر.س",
        devIcon :  "./images/rkaz.png",
        devname :"ركائز الانشاء",
        backgroundImage:"./images/project/Developer-image.png"
    }
    const ProjectsPageTwo = {
        icon : "./images/icons/ninjaGreen.png" ,
        name : "مشروع اُنس العقارية ",
        parent : "50٪ مُباع",
        price : "40,000,000 ر.س",
        // devIcon : "./images/ركائز.png",
        devIconTwo : "./images/rkaz.png",
        devname :" ركائز الانشاء",
        devnameTwo :"ركائز الانشاء",
        backgroundImage:"./images/project/Developer-image.png",
        // backgroundImageTwo:"./images/project/RkazProj.png",
    }
    const [boolen,boolenFunc] = useState(true)
    function toggle(e) {
        if(e.target.getAttribute("activeSide") == "notactive")boolenFunc(!boolen)
        toggleProjectFunc(!toggleProject)
        document.querySelector('[activeSide="active"]').setAttribute('activeSide' , "notactive")
        e.target.setAttribute( 'activeSide' , "active")
    }
  
    return (
        
    <>
    <div className="developers">
        <Nav activefour= "active"/>
        <div className="developers-header">
            <h1 className="developers-title">المطوّرين العقاريين</h1>
            <p className="developers-text">في أصول العقارية نستضيف أبرز المشاريع العقارية السكنية  لبيعها على المستفيدين، بقيادة أكبر الشركات العقارية في المملكة، وعرضها للبيع مع أعلى الضمانات، من مختلف الفئات،  الحكومي والقطاع الخاص، بدءًا من الفلل السكنية حتى الشقق، للحصول على أفضل العقارات مع أعلى إمكانـَات التمويل بالنسبة لك  .</p>
        </div>
        <div className="developers-project">
            <div className="developers-project-toggle">
                <span onClick={toggle} activeSide = "notactive" className="developers-side">مشاريع المطورين</span>
                <span onClick={toggle} activeSide = "active" className="developers-side">مشاريع الدولة</span>
            </div>
            <div className="developers-cards">
             {/* <span className="left-btn"><i class="fa-solid fa-arrow-left"></i></span> */}
             <div className="developers-card">
                <Fade left>
                {boolen ? 
                <ProjectCard backgroundImage = {ProjectsPageOne.backgroundImage } icon = {ProjectsPageOne.icon } devname =  {ProjectsPageOne.name}   percentage = {ProjectsPageOne.parent} price = {ProjectsPageOne.price } devicon = {ProjectsPageOne.devIcon }name = {ProjectsPageOne.devname } /> :
                <ProjectCard backgroundImage = {ProjectsPageOne.backgroundImage } icon = {ProjectsPageOne.icon } devname =  {ProjectsPageOne.name}   percentage = {ProjectsPageOne.parent} price = {ProjectsPageOne.price } devicon = {ProjectsPageOne.devIcon }name = {ProjectsPageOne.devname } /> 
                }
                </Fade>
                <Fade duration = {1000}>
                {boolen ? 
                <ProjectCard   backgroundImage = {ProjectsPageOne.backgroundImage } icon = {ProjectsPageOne.icon } devname =  {ProjectsPageOne.name}   percentage = {ProjectsPageOne.parent} price = {ProjectsPageOne.price } devicon = {ProjectsPageOne.devIcon }name = {ProjectsPageOne.devname } /> :
                <ProjectCard   backgroundImage = {ProjectsPageOne.backgroundImage } icon = {ProjectsPageOne.icon } devname =  {ProjectsPageOne.name}   percentage = {ProjectsPageOne.parent} price = {ProjectsPageOne.price } devicon = {ProjectsPageOne.devIcon }name = {ProjectsPageOne.devname } /> 
                }
                </Fade>
                <Fade right>
                {boolen ? 
                <ProjectCard backgroundImage = {ProjectsPageOne.backgroundImage } icon = {ProjectsPageOne.icon } devname =  {ProjectsPageOne.name}   percentage = {ProjectsPageOne.parent} price = {ProjectsPageOne.price } devicon = {ProjectsPageOne.devIcon }name = {ProjectsPageOne.devname } /> :
                <ProjectCard backgroundImage = {ProjectsPageOne.backgroundImage } icon = {ProjectsPageOne.icon } devname =  {ProjectsPageOne.name}   percentage = {ProjectsPageOne.parent} price = {ProjectsPageOne.price } devicon = {ProjectsPageOne.devIcon }name = {ProjectsPageOne.devname } /> 
                }
                </Fade>
             </div>
             {/* <span className="right-btn"><i class="fa-solid fa-arrow-right"></i></span>   */}
            </div>
        </div>
        <Footer/>
    </div>
    </>
    )
}


export default  function ProjectCard(props) {
    const test = [{num:"10",name:"عدد الوحدات"},{num:"+450 م٢",name:"المساحات"},{num:"+4 مليون ريال",name:"سعر الوحدات"}]
    const [likeB , SetlikeB] = React.useState( true);
    return <div className="project-card">
    <div className="project-header">
        <h3 className="project-header-title">مشرُوع اُنس</h3>
        <img className="project-header-icon" src={props.icon}></img>
    </div>

    <div className="project-img" style={{backgroundImage:`url(${props.backgroundImage})`}}>
            <div className="like-icon">
                {likeB ? 
                // <img onClick={
                    // function toggleLike(){
                        // SetlikeB(!likeB);  
                    // }
                // } src="./images/icons/like-icon.png" className="like-image" alt="like icon" /> 
                <i 
                onClick={
                    function toggleLike(){
                        SetlikeB(!likeB);  
                    }
                }
                id = "empty-hart" class="far fa-heart" ></i>: <i  onClick={
                    function toggleLike(){
                        SetlikeB(!likeB);  
                    }
                } id="hart" class="fas fa-heart"></i> } 
            </div>
        <div className="project-data-type">

                <div id="project-type">
                    {/* <h3>تمليك</h3> */}
                    {/* <i style={{color:"#4C4DDC"}} class="fa-regular fa-user"></i> */}
                </div>

                <div id="project-type" >
                    {/* <h3>الرياض</h3> */}
                </div>

            </div>
    </div>
    <div className="project-body">
        <h1 className="project-body-title">{props.devname}</h1>
        <p className="project-text">مشروع  فلل سكنية تمليك , بتصميم عصري حديث, وأنيق</p>

        <div className="project-body-data">
          {test.map((e) => {
            return <div className="project-box">
            <h1 id="numbers">{e.num}</h1>
            <h5 className="number-type">{e.name}</h5>
        </div>
          })
          }
        </div>

        <div className="project-numbers">
            <h1 className="project-percentage">{props.percentage}</h1>
            <span className="project-bar" style={{"--BackGroundWidth": `${props.percentage.match(/\d\d/g)[0]}%`}}></span>
            <h1 className="project-number">{props.price}</h1>
        </div>

    </div>
    <div className="project-footer">
        <div className="project-dev-name">
            <img src={props.devicon} alt="" />
            <h3 className="project-footer-text"><span>المطور</span>  {props.name}</h3>
        </div>
        <h3 className="project-footer-text"><span>النوع</span> فلل سكنيّة</h3>
    </div>
</div>
}
