import React from "react";


export default function Footer() {
    return (
        <>
            <div className="footer">
            <div className="footer-container">
                <div className="footer-btns">
                    <div style={{cursor : "pointer"}} className="drop-btn" onClick={() => {
                      window.scroll({
                        top: 0, 
                        left: 0, 
                        behavior: 'smooth' ,
                       });
                    }}><i class="fa-solid fa-arrow-up"></i></div>
                    <div className="seitch-btn">
                        <span className="ar-btn">عربي</span>
                        <span className="en-btn">English</span>
                        </div>
                </div>
                <div className="footer-main">
                    <div className="footer-contant">
                        <div className="footer-header">
                            <img   alt="ousal logo" src= "./images/icons/namelogo.png" className="footer-logo-name"/> 
                            <p className="footer-text">حي الياسمين، الطابق الأول، الرياض، المملكة العربية السعودية </p>
                        </div>
                        <div className="footer-lists">
                        <div className="footer-list" >
                                <ul style={{listStyle :"none" ,textAlign : "right"}}>
                                    <li style={{color : "#fff" , fontWeight: 700}}>اتصل بنا</li>
                                    <li>contact@ousol.ai</li>
                                </ul>
                                <div className="footer-logos">
                                    <a href="#"                                              className="footer-logo"><i class="fa-brands fa-youtube"></i></a>
                                    <a href="https://www.instagram.com/ousol.ai/?theme=dark" className="footer-logo"><i class="fa-brands fa-instagram"></i></a>
                                    <a href="https://twitter.com/ousol_ai"                   className="footer-logo"><i class="fa-brands fa-x"></i></a>
                                </div>
                            </div>
                            <ul className="footer-list">
                                    <li style={{color : "#fff" , fontWeight: 700 }}>المزيد</li>
                                    <li>أسئلة وأجوبة</li>
                                    <li>حول</li>
                            </ul>
                            <ul className="footer-list">
                                    <li style={{color : "#fff" , fontWeight: 700 }}>أصول</li>
                                    <li>البيع مع أصول</li>
                                    <li>العقارات</li>
                            </ul>
                        </div>
                    </div>
                    <ul className="footer-copyRight">
                        <li>سياسة الخصوصية</li>
                        <li style={ {width : "1px"} }>|</li>
                        <li>شروط الاستخدام</li>
                        <li style={ {width : "1px"} }>|</li>
                        <li>© 2023 ousol جميع الحقوق محفوظة</li>
                    </ul>
                </div>
                </div>    
            </div>       
        </>
    )
}