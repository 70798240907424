import React from "react";
import { Nav } from "./header";
import FooterIphone from "./foooteriphone";
import Footer from "./footer";
import { Fade } from "react-reveal";
export default function About() {
    return (
    <>

        <div className="about-header">
        <Nav activethere ="active"/>
        <Fade >
         <div className="about-header-container">
            <Fade bottom>
            <h1 className="about-header-tag">عن فريقنا</h1>
            <h1 className="about-main-title">أصول العقاريّة، المنصة الأحدث في المملكة العربية السعودية لبيع العقارات، وعرض مشاريع المطورين ! </h1>
            </Fade>
         </div>
         </Fade>
        </div>
        <div className="about-map">
            <div className="about-map-header">
                <Fade left>
                <div className="about-map-text">
                <p className="map-text">تُعدّ العقارات من أهم فئات الأصول التي يمكن امتلاكها من أجل بناء ثروة طويلة الأجل، ولكنّ الوصول الى كامل تفاصيل الأصل والتحقق من مواصفاتة صعب كما أنها غير بسيطة ومعقّدة. هناك الكثير من الأعمال الورقية، ودفعات أولى كبيرة، والسوق غير سهل أبداً. علاوة على ذلك، تستغرق الصفقات الجيّدة الكثير من الوقت والمعارف لتأمينه !</p>
                <p className="map-text">في أصول العقارية نستضيف أبرز الاعلانات العقارية السكنية، لبيعها على المستفيدين، بقيادة أكبر الشركات العقارية في المملكة، ومختلف الأفراد وعرضها للبيع مع أعلى الضمانات، من مختلف الفئات،  الحكومي والقطاع الخاص، بدءًا  من الفلل السكنية حتى الشقق، للحصول على أفضل العقارات مع أعلى إمكانـَات التمويل بالنسبة لك  .</p>
                 </div>
                </Fade>
                <Fade right>
            <h1 className="about-map-title">نحن ننشـأ بيئـة يكون فيها قطاع العقار رقمياً، موثوق، ومتوفّراً للجميع بـلا حُدود .</h1>
                </Fade>
            </div>
            <div className="about-map-cards">
                <Fade bottom delay = {600}>
                <Card card = "1" number = "80M+"sr ="S.R" type = "قيمة العقارات المدرجة"/>
                </Fade>
                <Fade bottom delay = {400}>
                <Card card = "2" number = "1M+"sr ="S.R" type = "عمولات المسوّقيـن"/>
                </Fade>
                <Fade bottom delay = {200}>
                <Card card = "3" number = "5K+" type = "المستخدمون المسجلون"/>
                </Fade>
                <Fade bottom>
                <Card card = "4" number = "30+" type = "عقارات المطورين المدرجة"/>
                </Fade>
            </div>
        </div>
        <div className="about-team">
            <div className="about-team-header">
                <h1 className="about-team-title">أعضاء مجلس الإدارة</h1>
                <p className="about-team-text">يتمتع فريقنا بسنوات من الخبرة في قيادة أبرز الشركات العقاريّة والتقنية، والخدمات المالية والاستشارات، وأنظمة المعلومات .</p>
            </div>
            <div className="about-team-cards">
            <Fade bottom><TeamCard number = "1" profile = "./images/profile.png" name = "خالد بن شايع ال شايع" department = "عضو مجلس ادارة"/></Fade>
            <Fade top delay = {100}><TeamCard number = "2" profile = "./images/profile.png" name = "سلطان بن فهد المقيل" department = "عضو مجلس ادارة"/></Fade>
            <Fade top delay = {100}><TeamCard number = "5" profile = "./images/profile.png" name = "مشاري بن قعيّد المطيري" department = "رئيس مجلس إدارة"/></Fade>
            <Fade bottom><TeamCard number = "3" profile = "./images/profile.png" name = "فيصل بن خليفة ال مبارك" department = "عضو مجلس ادارة"/></Fade>
            <Fade right delay = {200}><TeamCard number = "4" profile = "./images/profile.png" name = "عبدالله بن محمد المغامس" department = "عضو مجلس ادارة"/></Fade>
            {/* <Fade left delay = {200}> <TeamCard number = "6" profile = "./images/profile.png" name = "فيصل بن خليفة ال مبارك" department = "عضو مجلس ادارة"/></Fade> */}
            {/* <Fade righ delay = {50}> <TeamCard number = "7" profile = "./images/profile.png" name = "عبدالله الشايع" department = "رئيس قسم  التسويق والاعلان"/></Fade> */}
            {/* <Fade left delay = {150}> <TeamCard number = "8" profile = "./images/profile.png" name = "عبدالله الشايع" department = "رئيس قسم   المالية والمحاسبة"/></Fade> */}
            </div>
            <div className="join-us">
            <div className="join-us-contant">
                <h4 className="join-us-tag">انضمّ الى فريقنا</h4>
                <h1 className="join-us-title">شاركنا في جعل العقارات العالمية في متناول الجميع</h1>
                <p className="join-us-text">نحن نبحث دائمًا عن المواهب لمشاركتنا في توسعنا في الأسواق الدولية وخطوط الأعمال الجديدة</p>
            </div>
            <a href = "mailto: help@ousol.ai" className="join-us-btn">ارسل السيرة الذاتية</a>
        </div>
        </div>
        <div className="about-Partners">
            <h1 className="about-Partners-title">الشركاء</h1>
            <Fade bottom>
            <div className="Partners-images">
                {[0,0,0,0,0,0,0,0,0,0].map(() => {
                return <img src="./images/partrenrkaz.png" alt="Partner" className="Partner-img" />
                })}
            </div>
            </Fade>
        </div>
        <FooterIphone/>
        <Footer/>
    {/* </div> */}
    </>
    )
}

export function Card(props) {
    return (
    <>
    <div className="about-map-card" AboutCard = {props.card}>
        <h1 className="about-map-card-number">{props.number}{props.sr ?<span id="sr">{props.sr}</span>: ""}</h1>
        <h1 className="about-map-card-type">{props.type}</h1>
    </div>
    </>
    )
}

export function TeamCard(props) {
  return  (
        <>
        <div className="about-team-card" member = {props.number} >
            <div className="about-profile">
                <img className="member-profile" src={props.profile} alt="member profile" />
            </div>
            <div className="about-team-card-header">
                <h1 className="member-name">{props.name}</h1>
                <p className="member-department">{props.department}</p>
            </div>
        </div>
        </>
        )
}