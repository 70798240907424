import { Form } from "react-router-dom"




export default function Admine() {
    return <div className="admine">
                <section className="input-admine">
                    <div className="input-admine-nav">
                        <img src="../images/icons/OsuolLogo.png" alt="osual logo" className="input-admine-logo" />
                        <img src="../images/icons/OsualName.png" alt="osual name" className="input-admine-logo-name" />
                    </div>
                    <form className="input-admine-form" action="#">
                        <h1 className="input-admine-title">تسجيل الدخول</h1>
                        <input type="text" required placeholder="الجوال" className="input-admine-fild" />
                        <button className="input-admine-button">تسجيل الدخول</button>
                    </form>
                    <div className="another-way">
                        <h1 className="another-way-title">أو الدخول باستخدام</h1>
                        <div className="another-way-btns">
                                <button className="btn-google">Google</button>
                                <button className="btn-apple">Apple</button>
                        </div>
                    </div>
                </section>






                
                {/*  this section i will do it later */}
                <section className="image-admine">
                        <h1 className="image-admine-title">الطريقة الأحدث لأي شخص للعمل في العقارات</h1>
                        <img src="../images/project/Al Faisaliyah.png" alt="Faisaliyah"  className="Faisaliyah" />
                </section>
            </div>
}