import React from "react";
import { Fade } from "react-reveal";


export  function Grid(){
    return (
    <>
    <Fade>
    <div className="grid">
        <div className="grid-cards">
            <GridCar title = "1K+" text = "المستخدمون المسجلون"/>
            <GridCar title = "100M+ S.R" text = "قيمة المشاريع في أصول"/>
            <GridCar title = "10+" text = "مشروع مدرج في أصول"/>
            <GridCar title = "100k+" text = "عمولات المسوّقيـن"/>
        </div>    
        <div className="grid-contant">
          {/* <img  src="./images/icons/MainSnakex.png" alt="Osual logo" className="grid-logo" />  */}
          <h3 className="grid-logo-text">Powered by </h3> 
          <span  className="grid-logo-link" target="_blank">Snakex Company</span> 
        </div>
    </div>   
    </Fade>
    </>
    )
}
export function GridCar(props) {
    return (
    <>
     <div className="grid-card">
        <h1 className="grid-card-title">{props.title}</h1>
        <p className="grid-card-text">{props.text}</p>
    </div>
    </>
    )
}