import React from "react";



export default function Testimonial(){

    const [user , setUser] = React.useState(0);
    function starsGen(num){
        var indents = [];
        var min = Math.floor(num);
        var max = Math.ceil(num) ;
        for (var i = 0; i < num; i++) {
          indents.push(<li className="star"><i class="fa-solid fa-star"></i></li>);
        }
        if(min != max) {
            indents.pop();
            indents.push(<li className="star"> <i class="fa-solid fa-star-half-stroke"></i> </li>)
        };
        return indents;
    }
    let stars = [
        <ul className="reviews-stars">
        {starsGen(5)}
        </ul>  , 
        <ul className="reviews-stars">
            {starsGen(4.5)}
        </ul> 
    ] ;
    let titles = [
        "٧ آذار ٢٠٢٣" , 
        "٩ تشرين الاول ٢٠٢٤"
    ]
    let names = [
        "مشاري المطيري" , 
        "عبدالله الشايع", 
    ]
    let text = [
        "أنا سعيد حقًا بمعرفة أصول العقارية . يمكنك الاعلان في مدينة نابضة بالحياة مثل الرياض دونالحاجة إلى مبلغ كبير من المال. إنها طريقة رائعة لكسب دخل ثانٍ. كانت خدمة العملاء ممتازة، وكانوا مستعدين للإجابة على أي سؤال بسرعة. كنت أبحث كثيراً عن طريقة لكسب المال، وكانت أصول العقارية بمثابة حلم تحقق . شكرا لكم على هذه الفرصة، ونتطلع إلى المزيد من الاستثمارات.",
        "أنتم تحلون مشكلة عدم الحاجة إلى استكشاف العديد من فرص الاستثمار المختلفة، مع الاحتفاظ بفرصة الاستثمار في أصل يهمني حقًاً وببضع نقرات فقط"
    ]
    const newspaperSpinning = [
        { opacity: 0 },
        { opacity: 1},
      ];
      
      const newspaperTiming = {
        duration: 1000,
        iterations: 1,
      };
    return (
    <>
    <div className="testimonial">
        {/* <div className="testimonial-background"></div> */}
        <div className="testimonial-main">
            <div className="card-container" style={{display : "flex" , flexDirection :"column" , gap : "19px"}}>
            <div className="testimonial-card">
                 <div className="testimonial-card-header">
                    <div className="testimonial-reviews">
                        {stars[user]}
                        <div className="reviews-date">{titles[user]}</div>
                    </div>
                    <h1 className="testimonial-card-title">منصة آمنة ، وسهلة لشراء أصول عن طريق أصول العقارية</h1>
                 </div>
                 <p className="testimonial-card-text" >{text[user]}</p>
                 <h2 className="user-name">{names[user]}</h2>
            </div>
            <div  className="testimonial-btns">
            <span onClick={
                function(e) {
                    if(user > 0 ) setUser(user-1);
                    else setUser(user+1);
                    document.querySelector(".testimonial-card").animate(newspaperSpinning, newspaperTiming);
                  }
            } className="testimonial-left"><i class="fa-solid fa-arrow-left"></i></span>
            <span onClick={
                 function(e) {
                    if(user < 1 )setUser(user+1);
                    else setUser(0);
                    document.querySelector(".testimonial-card").animate(newspaperSpinning, newspaperTiming);
                  }
            } className="testimonial-right"><i class="fa-solid fa-arrow-right"></i></span>
           </div>
            </div>
            <h1 className="testimonial-title">لا تعتمد على كلامنا فقط، انظر الى ماذا يقال عنّا من عملاءنا</h1>
        </div>
    </div>
    </>
    )
}