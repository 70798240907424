import React from "react";
import Header from "./header";
import { Grid } from "./grid";
import  Method from "./method";
import Iphone from "./iphone";
import Cards from "./cards";
import Investment from "./investment";
import Testimonial from "./testimonial";
import Calculator from "./calculator";
import Projects from "./Projects";
import Bounty from "./bounty";
import EndCards from "./EndCards";
import FooterIphone from "./foooteriphone";
import Footer from "./footer";
export default function Main() {
    
    return (
    <>
    <Header/>
    <Grid/>
    <Method/>
    <Iphone/>
    <Cards/>
    <Investment/>
    <Testimonial/>
    <Calculator/>
    <Projects/>
    <Bounty/>
    <EndCards/>
    <FooterIphone/>
    <Footer/>
    </>
    )
}