import React, { useState } from "react";
import MobileStoreButton from 'react-mobile-store-button';
import { Fade } from "react-reveal";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
export default function Header() {
    const handleScroll = () => {
        var add =  (window.scrollY - 0) / 10
        if(lastScrollTop < window.scrollY){
            if(add > 0 && window.scrollY <= 550) {
                document.getElementsByClassName("header-clip")[0].style.clipPath = "polygon(0 54%, 100% "+ (85-add) +"%, 100% 100%, 0% 100%)";
            }
       }else{
        if(add > 0 && add <= 85) {
            document.getElementsByClassName("header-clip")[0].style.clipPath = "polygon(0 54%, 100% "+ (85 - add) +"%, 100% 100%, 0% 100%)";
        }
       }
        lastScrollTop = window.scrollY ;
      };
      var lastScrollTop = 0;
      window.addEventListener('scroll', handleScroll);
    return (
    <>
    <div className="header">
    <Fade top delay = {100}>
        <div className="header-read-more">
            <h1 className="read-more" dir="rtl">أصول العقاريّة، المنصة الأحدث في المملكة العربية السعودية لبيع العقارات، وعرض مشاريع المطورين ! <a href="https://twitter.com/ousol_ai" style={{color : "#41CE8E"}}>  في إجراءات الرخصة </a> </h1>
        </div>
     </Fade>
        <Nav/>
        <Fade bottom>
        <header className="header-main">
             <div className="header-clip">

            </div> 
            <div className="header-contant-img">
                <img   src= "./images/newIphone.png"  alt="iphone" className="header-iphone" />
                <div className="haeder-contant">
                    <h1 className="header-title">
                    أصُول العقاريّة، أكثـر موثوقيـّة   
                    <br />
                    خيارات شمُولية، أطر تنظِيمية
                    </h1>
                    <p className="header-text">
                         اعلن في أصول، بطريقة حديثة استراتيجية ، وأكثر موثوقيـّة <br></br> من أي مكان في المملكة العربية السعودية 
                            !   
                    <a href="https://twitter.com/ousol_ai" style={{color : "#4C4DDC" , fontWeight: 600}}>
                         دون أي عمولة 
                    </a>
                    </p>
                    <div className="header-video">
                        <p className="header-video-text">طريقة العمل</p>
                        <a className="header-play-btn"><i class="fa-solid fa-play"></i></a>
                    </div>
                    <div className="header-btns">
                    <span className="qrcode-container"><QRCode value=""/></span>
                    <img   src="./images/button-play.webp" style={{width: "195.577px",height: "61.599px" }} alt="google play btn" />
                    <img   src="./images/button-app.png" style={{width: "195.577px",height: "61.599px" }} alt="app store btn" />
                    </div>
                </div>
            </div>
        </header>
        </Fade>
    </div>
    </>
    )
}

// navtion header
export function Nav(props){
    const [ChangeView , UIFunc] = useState(false)
    function handleChange() {
        ChangeView ? document.body.setAttribute('close',"false")
        :document.body.setAttribute('close',"true")
        UIFunc(!ChangeView)
    }
    const [NavPostion ,  NavControl] = useState(true)


        let prevpostion = window.screenTop
        let count = 0
        window.addEventListener("scroll" , () => {
        let currentScrollPos = window.scrollY
        if(currentScrollPos < prevpostion & currentScrollPos > 1000){
               if(count < 50) count++;
               if(count == 0) document.querySelector(".header-nav").setAttribute("showNav" , "true")
        }else {
                if(count != 0 )count--;
                if(count == 0)document.querySelector(".header-nav").setAttribute("showNav" , "false")
        }
        prevpostion = currentScrollPos
    })

    return (
        <>
        <Fade top>
        <nav className="header-nav" >
                    <div className="haeder-nav-menus">
                    <Link to={"/"} className ="logo-container">
                        <img src="./images/icons/OsuolLogo.png" logo ="logo" alt="logo Osuol" />
                        <img src="./images/icons/OsualName.png" logo ="name" alt="logo name" />
                    </Link>
                    
                    </div>

                   
                    
                <div className="haeder-nav-menus">
                    <div className="haeder-nav-menu">
                    <Link Linkactive = {props.activeone} to = "/" >الاسئلة</Link> 
                    <Link Linkactive = {props.activetwo} to = "/" >البيع مع أصول</Link> 
                    <Link Linkactive = {props.activethere} to="/">حول</Link>
                    <Link Linkactive = {props.activefour} to="/developers">المطورين</Link>
                    </div>
                    </div>
                    <div className="header-nav-btns">
                    <a className="header-start-btn">ابدأ</a>
                    {/* <a className="header-sing-in-btn">سجّل الدخول</a> */}
                    <div>
                        
                    </div>
                    {/* <a href="" className="header-nav-language"> */}
                    {/* <span><i class="fa-solid fa-angle-down"></i>AR</span>       */}
                    {/* <i class="fa-solid fa-globe"></i> */}
                    {/* </a> */}
                    </div>

                <div className="mobile-nav" style={{display : "none"}}>
                <div className="mobile-start-btn">
                <i class="fa-solid fa-bars" onClick={handleChange}></i>
                    <a className="header-start-btn" style={{
                        width : "80px"
                    }}>ابدأ</a>
                    </div>
                    {/* <img src="./images/icons/OsuolLogo.png" className="haeder-nav-logo" logo ="logo" alt="logo Osuol" /> */}
                    <Link to={"/"} className ="logo-container">
                        <img src="./images/icons/OsuolLogo.png" logo ="logo" alt="logo Osuol" />
                        <img src="./images/icons/OsualName.png" logo ="name" alt="logo name" />
                        </Link>
                </div>
                {
                    ChangeView ?
                    <MobileNavView handleChange={handleChange}/>:null 
                }
        </nav>
        </Fade>
        </>
    )
}
export function MobileNavView({handleChange}) {
    return<Fade bottom duration = {300}  >
    <div className="mobile-view">
        <div className="mobile-view-nav">
        <i class="fa-solid fa-xmark" onClick={handleChange}></i>
        <img  src="./images/ousal-logo.png" alt="logo" className="mobile-nav-logo" />
        </div>
        <div className="mobile-view-body">
            <Link onClick={handleChange} to="/" >الرئيسية</Link>
            <Link onClick={handleChange} to="/developers" >المطورين</Link>
            <Link onClick={handleChange} to="/" >حول</Link>
            <Link onClick={handleChange} to={"/"} >البيع مع أصول</Link>
            <Link onClick={handleChange} to = "/" >الاسئلة</Link>
        </div>
        <div className="mobile-view-footer">
            <a href="#" className="mobil-btn">ابدأ</a>
            <div class="seitch-btn"><span class="ar-btn">عربي</span><span class="en-btn">English</span></div>
        </div>
    </div>
    </Fade>
}