import React from "react";
import { Fade } from "react-reveal";


export default function Iphone() {
    return (
        <>
        <div className="iphone">
            <div className="iphone-container">

            <div  className="iphone-contant">
                <div className="iphone-contant-border"></div>
                <p lang="ar" dir="rtl" className="iphone-text">نعمل على ظبط جميع اعلانات المسوقين العقاريين بواسطة الهوية الوطنية عبر أبشر، وذلك التزمّا بأنظمة الدولة ، وسوف يتم رفض أي مسوّق غير موثق . نحن بصدد إطلاق أكبر سوق رئيسي رقمي للمسوقين العقاريين الأكثر نشاطًا في المملكة .</p>
            </div>=

            <img src="./images/GridIphone.png" alt="iphone" className="iphone-img"/>
            <div className="iphone-broswer"><img src="./images/icons/browser.png" className="broswer-icon" alt="" /></div>
            </div>
            <div className="iphone-container">
            <div  className="iphone-contant">
                <div className="iphone-contant-border"></div>
                <p lang="ar" dir="rtl" className="iphone-text">ما بين مشاريع مطوّرين, واعلانات المسوّقين, وأصول ملاّك ، مع أمكانية شراء العقار من  المطور، وذلك بدفع عربون مباشرة، من خلال بوابات الدفع المتاحة لدينا، سداد / أبل باي / مدى .</p>
            </div>
            <img src="./images/iphonetwo.png" alt="iphone" className="iphone-img"/>
            <div className="iphone-broswer"><img src="./images/icons/browser.png" className="broswer-icon" alt="" /></div>
            </div>

            <div className="iphone-container">
            <div  className="iphone-contant">
                <div className="iphone-contant-border"></div>
                <p lang="ar" dir="rtl" className="iphone-text">إلزامية تعبئة الخانات أثناء انشاء الاعلان . واكتمال تفاصيل الاعلان ، وينطبق ذلك على جميع اعلانـات العقارات المطروحة لدينا ، مهمتنا هي ضبط وايضاح تفاصيل العقارات المدخلة .</p>
            </div>
            <img  src="./images/iphonethree.png" alt="iphone" className="iphone-img"/>
            <div className="iphone-broswer"><img  src="./images/icons/browser.png" className="broswer-icon" alt="" /></div>
            </div>
            <div className="iphone-container">
            <div  className="iphone-contant">
                <div className="iphone-contant-border"></div>
                <p lang="ar" dir="rtl" className="iphone-text">وفرنا لائحة، تجمع أفضل المسوقين العقاريين، تحقيقًا للصفقات العقارية في المملكة، بناء من لوحة احصائية، يوضح فيها العروض المباعـة من قبل المسوق، واجمالي السعي</p>
            </div>
            <img  src="./images/iphoneFour.png" alt="iphone" className="iphone-img"/>
            <div className="iphone-broswer"><img  src="./images/icons/browser.png" className="broswer-icon" alt="" /></div>
            </div>


        </div>
        </>
    )
}