import React from "react";
import { Fade } from "react-reveal";
import ProjectCard from "./developers"

export default function Projects(){

    return (
        <>
        <div className="projects">
            <div className="projects-header">
                <h1 className="projects-title">مشاريع  عقارية قادمة</h1>
                <p className="projects-text">نستضيف مشاريع الدولة لبيعها على المستفيدين, بقيادة أكبر الشركات العقارية في المنطقة، لتقديم أفضل العقارات مع أعلى الضمانات بالنسبة لك </p>
            </div>
            {/* <span className="projects-left-btn"><i class="fa-solid fa-arrow-left"></i></span> */}
            <div style={{
                display:"flex" ,
                justifyContent:"center" ,
                alignItems:"center",
                gap :"85px",
                flexWrap:"wrap"
            }}>
            <Fade left duration = {1000}>
            <ProjectCard backgroundImage = "./images/veli.png" icon = "./images/icons/ninjaGreen.png" devname = "مشروع الأصالة الفارهة " percentage = "50٪ مُباع" price = "40,000,000 ر.س" devicon =  "./images/ousal-logo.png" name = "أصول العقارية"/>
            </Fade>
            <Fade duration = {500} >
                <div id="midImage">
                    <ProjectCard backgroundImage = "./images/veli.png" icon = "./images/icons/ninjaGreen.png" devname = "مشروع الأصالة الفارهة " percentage = "50٪ مُباع" price = "40,000,000 ر.س" devicon =  "./images/ousal-logo.png" name = "أصول العقارية"/>
                </div>
            </Fade>
            <Fade right duration = {1000}>
            <ProjectCard backgroundImage = "./images/veli.png" icon = "./images/icons/ninjaGreen.png" devname = "مشروع الأصالة الفارهة " percentage = "50٪ مُباع" price = "40,000,000 ر.س" devicon =  "./images/ousal-logo.png" name = "أصول العقارية"/>
            </Fade>
            </div>
            {/* <span className="projects-right-btn"><i class="fa-solid fa-arrow-right"></i></span>    */}
            <a className="Browse-btn" href="https://x.com/ousol_ai">تصفّح العقارات المتوفّرة</a>
        </div>
        </>
    )
}


