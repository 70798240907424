import React,{useRef, useState} from "react";




export default function Calculator () {

    const [message, setMessage] = useState(100000);
    const [percentage, setpercentage] = useState(0.50);
    const [percentageTwo, setpercentageTwo] = useState(0.50);
    // const [message, setMessage] = useState('');
    const handleChange = event => {
        setMessage(event.target.value);
        event.target.style.backgroundSize = (event.target.value - event.target.min) * 100 / (event.target.max - event.target.min) + '% 100%'
      };
      const handlePercentage = event => {
      setpercentage(event.target.value /100);
      event.target.style.backgroundSize = (event.target.value - event.target.min) * 100 / (event.target.max - event.target.min) + '% 100%'
      }
      const handlePercentageTwp = event => {
        setpercentageTwo(event.target.value / 100);
        event.target.style.backgroundSize = (event.target.value - event.target.min) * 100 / (event.target.max - event.target.min) + '% 100%'
        }

    return (
        <>
        <div className="calculator">
            <h1 className="calculator-title">حاسبة الاستثمار</h1>
            <div className="calculator-body">
                <div className="calculator-left">
                    <div className="calculator-left-header">
                        <h1 className="calculator-left-title">عائد الاستثمار المتوقع</h1>
                        <div className="calculator-left-number">
                            <i style={{fontSize : "20px"}} class="fa-regular fa-circle-question"></i>
                            <h1 className="calculator-y">في ٥ سنوات</h1>
                            <h1 className="calculator-price" >SAR { Math.ceil((+(percentageTwo * 4) * message) + +((percentage * 4) * message) + +message)}</h1>
                        </div>
                    </div>
                    <div className="calculator-data-input">
                        <Data pingColor = "#121C30" name = "الإستثمار" number = {message}/>
                        <Data pingColor = "#41CE8E" name = "عائد الإيجار الإجمالي" number = {Math.ceil((+(percentageTwo * 4) * message))}/>
                        <Data pingColor = "#4C4DDC" name = "قمية نمو رأس المال" number = {Math.ceil((percentage * 4) * message)}/>
                    </div>
                    <div className="calculator-data-output">
                        <div className="calculator-main-axis">
                            <div className="main-axis-charts">
                            <Chart parent =  "170px" black = {`${( +message / ((+(percentageTwo *5) * message) + +((percentage *5) * message) + +message)).toFixed(5) * 100}%`}  green = {`${((+(percentageTwo *5) * message) / ((+(percentageTwo *5) * message) + +((percentage *5) * message) + +message)).toFixed(5) * 100}%`}  purpl = {`${((+(percentage *5) * message) / ((+(percentageTwo *5) * message) + +((percentage *5) * message) + +message)).toFixed(3) * 100}%`}/>
                            <Chart parent =  "142px" black = {`${( +message / ((+(percentageTwo *4) * message) + +((percentage *4) * message) + +message)).toFixed(4) * 100}%`}  green = {`${((+(percentageTwo *4) * message) / ((+(percentageTwo *4) * message) + +((percentage *4) * message) + +message)).toFixed(4) * 100}%`}  purpl = {`${((+(percentage *4) * message) / ((+(percentageTwo *4) * message) + +((percentage *4) * message) + +message)).toFixed(3) * 100}%`}/>
                            <Chart parent =  "117px" black = {`${( +message / ((+(percentageTwo *3) * message) + +((percentage *3) * message) + +message)).toFixed(3) * 100}%`}  green = {`${((+(percentageTwo *3) * message) / ((+(percentageTwo *3) * message) + +((percentage *3) * message) + +message)).toFixed(3) * 100}%`}  purpl = {`${((+(percentage *3) * message) / ((+(percentageTwo *3) * message) + +((percentage *3) * message) + +message)).toFixed(3) * 100}%`}/>
                            <Chart parent =  "90px" black = {`${( +message / ((+(percentageTwo * 2) * message) + +((percentage * 2) * message) + +message)).toFixed(3) * 100}%`}  green = {`${((+(percentageTwo * 2) * message) / ((+(percentageTwo * 2) * message) + +((percentage * 2) * message) + +message)).toFixed(3) * 100}%`}  purpl = {`${((+(percentage * 2) * message) / ((+(percentageTwo * 2) * message) + +((percentage * 2) * message) + +message)).toFixed(3) * 100}%`}/>
                            <Chart parent =  "65px" black = {`${( +message / ((+percentageTwo * message) + +((percentage) * message) + +message)).toFixed(3) * 100}%`}  green = {`${((+percentageTwo * message) / ((+percentageTwo * message) + +((percentage) * message) + +message)).toFixed(3) * 100}%`}  purpl = {`${((+percentage * message) / ((+percentageTwo * message) + +((percentage) * message) + +message)).toFixed(3) * 100}%`}/>
                            </div>
                            <div className="main-axis-number">
                                <span className="main-axis-item">2028</span>
                                <span className="main-axis-item">2027</span>
                                <span className="main-axis-item">2026</span>
                                <span className="main-axis-item">2025</span>
                                <span className="main-axis-item">2024</span>
                            </div>
                        </div>
                        <div className="calculator-cross-axis">
                            <span className="cross-axis-item" >{Math.ceil((+(percentageTwo* 4) * message) + +((percentage * 4) * message) + +message)}</span>
                            <span className="cross-axis-item" >{Math.ceil((+(percentageTwo* 3) * message) + +((percentage * 3) * message) + +message)}</span>
                            <span className="cross-axis-item" >{Math.ceil((+(percentageTwo* 2) * message) + +((percentage * 2) * message) + +message)}</span>
                            <span className="cross-axis-item" >{Math.ceil((+(percentageTwo* 1) * message) + +((percentage * 1) * message) + +message)}</span>
                            <span className="cross-axis-item" >{message}</span>
                            {/* <span className="cross-axis-item" >40,000</span> */}
                        </div>
                    </div>
                </div>
                <div className="calculator-right">
                    <h1 className="calculator-right-title">ما هو مقدار المبلغ الذي ترغب في استثماره؟</h1>
                    <div className="calculator-right-ranges">
                        <div className="range">
                            <div className="range-header">
                                <h1 className="range-number">SAR {message}</h1>
                                <h1 className="range-title">الإستثمار الأول</h1>
                            </div>
                                <input  id="range-one" min = {"1000"} max = {"200000"} step="1000" /*defaultValue = {defaultMessage}*/  onChange={handleChange}  type={"range"} className="range-bar"></input>
                        </div>
                        <div className="range">
                            <div className="range-header">
                                <h1 className="range-number">{Math.ceil(percentage * 100)}%</h1>
                                <h1 className="range-title">نمو قيمة العقار (5 سنوات)</h1>
                            </div>
                            <input step={5}  id="range-two" min = {"5"} max = {"100"} /*defaultValue = {defaultPercentage}*/  onChange={handlePercentage}   type={"range"} className="range-bar"></input>
                        </div>
                        <div className="range">
                            <div className="range-header">
                                <h1 className="range-number">{ percentageTwo ? Math.ceil(percentageTwo*100): 8}%</h1>
                                <h1 className="range-title">عائد الإيجار السنوي المتوقع</h1>
                            </div>
                            <input step={5}  id="range-two" min = {"5"} max = {"100"}  /*id="range-three"*/   onChange={handlePercentageTwp}   type={"range"}  className="range-bar"/>
                        </div>
                    </div>
                <p className="calculator-right-footer">جميع العوائد المتوقعة تعكس قيمة المبلغ قبل إضافة أي تكاليف ملكية ورسوم المنصة، واستناداً إلى فترة احتفاظ مدتها 5 سنوات. نتوقع أن تنمو قيمة الأصول بنسبة 30٪ خلال السنوات الخمس القادمة.</p>
                </div>
            </div>
        </div>
        </>
    )
}


export function Data (props) {
    return (
    <>
    <div className="calculator-one-data">
        <div className="ping-name">
            <h1 className="calculator-ping-name">{props.name}</h1>
            <span className="ping" style={{backgroundColor : props.pingColor}}></span>
        </div>
        <h1 className="calculator-ping-number">{props.number}</h1>
    </div>
    </>
    )
}

export function Chart(props){
    return (
    <>
    <div className="chart" style={{height:props.parent}}>
        <div style={{height : props.purpl  }} className="purple-chart"></div>
        <div style={{height :  props.green  }} className="green-chart"></div>
        <div style={{height :  props.black  }} className="black-chart"></div>
    </div>
    </>
    )
}




