import React from "react";
import { Fade } from "react-reveal";


export default function Investment() {
    return (
        <>
        <div className="investment">
            <div className="investment-header">
              <h1 className="investment-title">
               استثمار العقار أسهل,
                <span style={{color : "#4C4DDC"}}>  مع أصُول الاستثمار</span>
               </h1>
              <p className="investment-text">
                90% من أثرياء العالم قاموا ببناء ثرواتهم من خلال العقارات، لكن الوصول إليها معقّد وصعب جداً من ناحية الاستثمار والوصول    
                <span style={{fontWeight: 600}}> - وهنا يبدأ دورنا !</span>
                </p>
            </div> 
            <div className="investment-grid">
                <Fade bottom delay = {400}>
                <InvestmentCard src = "./images/investmentFour.png" title = "احصل على الأرباح المالية" text = "بعد الانتهاء من التطوير. وادراج المشروع في أصول العقاريـّة, يتم ايداع الأرباح في حسابك البنكيّ ." />
                </Fade>
                <Fade bottom delay = {300}>
                <InvestmentCard src = "./images/investmentThere.png" title = "اتفق مع المطوّر العقاري" text = "بعد موافقة كلا الأطراف على الأرض بعقود مبرمة, يتم البدء في تطوير الأرض, وارسال اشعارات بشأن اخر التطورات ." />
                </Fade>
                <Fade bottom delay = {200}>
                <InvestmentCard src = "./images/investmentTwo.png" title = "تلقى عروض من المطوّرين " text = "بعد ادراج الأرض في خانة أصول الاستثمار, تلقى عروض المطورين العقاريين" />
                </Fade>
                <Fade bottom delay = {0}>
                <InvestmentCard src = "./images/investmentone.png" title = "استثمر-أضِف- أصولك  بدءًا من أرض واحدة مهما كانت" text = "مع أصول الاستثمار - لا داعي للرهن العقاري أو لقضاء سنوات من الانتظار لارتفاع قيمة أرضك" />
                </Fade>
            </div>
        </div>
        </>
    )
}


export function InvestmentCard(props) {
    return (
        <>
        <div className="investment-card">
            <img   src = {props.src} className="investment-card-icon" alt = "icon"/>
            <div className="investment-card-contant">
                <h1 className="investment-card-title">{props.title}</h1>
                <p className="investment-card-text">{props.text}</p>
            </div>
        </div>
        </>
    )
}