import React from "react";
import { Fade } from "react-reveal";


export default function EndCards(){
    return (
        <>
        <div className="end-cards">
            <div className="end-cards-container">
                 <Fade bottom>
                <EndCard src = "./images/icons/up.png" title = "احتياط ضد التضخم المالي" text = "العقارات هي أقدم فئة أصول في التاريخ وكانت دائمًا وسيلة احتياط خلال مراحل التضخّم المالي، تمامًا مثل الذهب"/>
                <EndCard number = "2" src = "./images/icons/chart.png" title = "زيادة قيمة رأس المال" text = "بالإضافة إلى مدخول دون مجهود، فإنّ العقارات تزداد قيمتها مثل الأسهم، مما يخلق ثروة طويلة الأجل للمستثمرين"/>
                </Fade>
                <Fade bottom delay={200}>
                <EndCard src = "./images/icons/gold.png" title = "مخزن الثروة" text = "يطمح الجميع إلى امتلاك منزل، ممّا يجعل العقارات مخزنًا ملموسًا للثروة ذات قيمة استرداد عالية جدًا"/>
                <EndCard number = "2" src = "./images/icons/pluse.png" title = "دخل دون مجهود" text = "تولّد العقارات مدخولاً ثابتاً دون مجهود مثل السندات، في شكل مدفوعات إيجار شهرية"/>
                </Fade>
            </div>
            <div className="end-cards-contant">
                <Fade bottom>
                <h1 className="end-cards-title">تَجمع العقارات أفضل الخصائص من فئات الأصول</h1>
                <p className="end-cards-text">إنها واحدة من أهم فئات الأصول لامتلاك وبناء ثروة طويلة الأجل</p>
                </Fade>
            </div>
            <div className="end-cards-clip"></div>
        </div>
        </>
    )
}

export function EndCard(props) {
    return (
        <>
        <div className="end-card" number = {props.number}>
            <img src = {props.src} alt="icon" className="end-card-icon" />
            <div className="end-card-contant">
                <h1 className="end-card-title">{props.title}</h1>
                <p className="end-card-text">{props.text}</p>
            </div>
        </div>
        </>
    )
}