import React,{useRef , useEffect, useState} from "react";
import { Fade } from "react-reveal";


export default function Method() {
    const ref = React.useRef(null);
    useEffect(() => {
      }, []);

    return (
        <>
        <div className="method">
        <Fade bottom delay = {0}>
            <div className="method-header">
                <h1 className="method-title">لماذا أصول</h1>
                <p className="method-text">منصّتنا متاحة للعقاريين المتمرّسين في جميع انحاء المملكة</p>
            </div>
            </Fade>
            <div className="method-cards">
                <Fade bottom delay = {300}>
                <MethodCard number = "4" id = "04" title = "تجربة استثنائية" text = "للزوار المشترين, والملاّك المستثمرين, والمسوقين النشطين, والمطورين المتمرسين ."/>
                </Fade>
                <Fade bottom delay = {200}>
                <MethodCard number = "3"id = "03" title = "أطر تنظيمية" text = "ألزامية تعبئة الخانات أثنـَاء انشاء الاعلان، وكماليّة تفاصيل الاعلان . " />
                </Fade>
                <Fade bottom delay = {100}>
                <MethodCard number = "2" res ={ref} id = "02" title = "خيارات شموليّة" text = "مجموعة واسعة من العقارات، ما بين  مشاريـع المطوّرين، واعلانات المسوقين، وأصول الملاّك"/>
                </Fade>
                <Fade bottom delay = {0}>
                <MethodCard number = "1" active = "idActive" id = "01" title = "أكثر موثوقية" text = "ارتباط كل اعلان عقاري برخصة المعلن، يتم اعتمادها، بعد التحقق مباشرة  من أبشر الوطنية ."/>
                </Fade>


            </div>
            <Fade>  
                <MethodCardSmall/>
            </Fade>

        </div>
        </>
    )
}
export function MethodCard(props) {


    return (
        <>
        <div className='method-card' card = {props.number} >
            <div className="method-card-tag">
                <span className="method-card-id" id={props.active} ref={props.res} onClick = {(e) => {

                    let element = document.querySelector(".iphone")
                    if(e.target.innerText == "01") { 
                         element.style.animationName = "goLeft1"
                         setTimeout(() => element.style.left = "0%" , 1000)
                         document.querySelector("#idActive").removeAttribute("id")
                         e.target.setAttribute("id" , "idActive");
                     } else if( e.target.innerText == "02" ){
                        element.style.animationName = "goLeft2"
                        setTimeout(() => element.style.left = "-100%" , 1000)
                        document.querySelector("#idActive").removeAttribute("id")
                        e.target.setAttribute("id" , "idActive");
                     } else if(e.target.innerText == "03") {
                        element.style.animationName = "goLeft3"
                        setTimeout(() => element.style.left = "-200%" , 1000)
                        document.querySelector("#idActive").removeAttribute("id")
                        e.target.setAttribute("id" , "idActive");
                     } else {
                        element.style.animationName = "goLeft4"
                        setTimeout(() => element.style.left = "-300%" , 1000)
                        document.querySelector("#idActive").removeAttribute("id")
                        e.target.setAttribute("id" , "idActive");
                    }


                }}>{props.id}</span>
                <h3 className="method-card-title" dir = "rtl">{props.title}</h3>
            </div>
            <p className="method-card-text" dir = "rtl">{props.text}</p>
        </div>
        </>
    )
}


export function MethodCardSmall(props) {
    const [Attnumber , handleChange] = useState(1)
    setInterval(() => {
    if(document.querySelector(".method-card-small-header #smallactive").getAttribute("btnNumb") != null){
        handleChange(document.querySelector(".method-card-small-header #smallactive").getAttribute("btnNumb"))
    }
    }, 1000);
    const titles = {
        1 : "أكثر موثوقية" ,
        2 : "خيارات شموليّة" ,
        3 : "أطر تنظيمية" ,
        4 : "تجربة استثنائية" 
    }
    const text = {
        1 : "ارتباط كل اعلان عقاري برخصة المعلن، يتم اعتمادها، بعد التحقق مباشرة  من أبشر الوطنية ." ,
        2 : "مجموعة واسعة من العقارات، ما بين  مشاريـع المطوّرين، واعلانات المسوقين، وأصول الملاّك" ,
        3 : "ما بين مشاريع مطوّرين, واعلانات المسوّقين, وأصول الملاّك" ,
        4 : "للزوار المشترين, والملاّك المستثمرين, والمسوقين النشطين, والمطورين المتمرسين ." 
    }
    return (
        <>
        <div className="method-card-small" style={{display : "none"}}>
            <div className="method-card-small-header">
                <CardSmallBtn number = "1" text = "01" isactive = "true"/>
                <CardSmallBtn number = "2" text = "02" />
                <CardSmallBtn number = "3" text = "03" />
                <CardSmallBtn number = "4" text = "04" />
            </div>
            <h3 className="method-small-title">{titles[Attnumber]}</h3>
            <p className="small-card-text">{text[Attnumber]}</p>
        </div>
        </>
    )
}

export function CardSmallBtn(props) {
      let count = 4;
    setInterval(() => {
        let element = document.querySelector(".iphone")
        let e = document.querySelector(".method-cards div:nth-child("+count+") .method-card-id")
        if(window.innerWidth >= 450){

        if(count == 4) { 
            element.style.animationName = "goLeft1"
            setTimeout(() => element.style.left = "0%" , 1000)
            document.querySelector("#idActive").removeAttribute("id")
            e.setAttribute("id" , "idActive");

        } else if( count == 3 ){
            element.style.animationName = "goLeft2"
            setTimeout(() => element.style.left = "-100%" , 1000)
            document.querySelector("#idActive").removeAttribute("id")
            e.setAttribute("id" , "idActive");
        } else if(count == 2) {
            element.style.animationName = "goLeft3"
            setTimeout(() => element.style.left = "-200%" , 1000)
            document.querySelector("#idActive").removeAttribute("id")
            e.setAttribute("id" , "idActive");
        } else {
            element.style.animationName = "goLeft4"
            setTimeout(() => element.style.left = "-300%" , 1000)
            document.querySelector("#idActive").removeAttribute("id")
            e.setAttribute("id" , "idActive");
       }
       if(count == 1) count = 4;
       else count--;
        }
    }, 5000);
    return (
        <h1  btnNumb = {props.number} id={props.isactive  ? "smallactive" : "not"} onClick = {(e)=>{
            count = 1;
            let element = document.querySelector(".iphone")
            if(e.target.getAttribute("btnNumb") == "1") { 
                 element.style.animationName = "goLeft1"
                 setTimeout(() => element.style.left = "0%" , 1000)
                 document.querySelector("#smallactive").removeAttribute("id")
                 e.target.setAttribute("id" , "smallactive");
             } else if( e.target.getAttribute("btnNumb") == "2" ){
                element.style.animationName = "goLeft2"
                setTimeout(() => element.style.left = "-100%" , 1000)
                        document.querySelector("#smallactive").removeAttribute("id")
                 e.target.setAttribute("id" , "smallactive");
             } else if(e.target.getAttribute("btnNumb") == "3") {
                element.style.animationName = "goLeft3"
                setTimeout(() => element.style.left = "-200%" , 1000)
                        document.querySelector("#smallactive").removeAttribute("id")
                 e.target.setAttribute("id" , "smallactive");
             } else {
                element.style.animationName = "goLeft4"
                setTimeout(() => element.style.left = "-300%" , 1000)
                        document.querySelector("#smallactive").removeAttribute("id")
                 e.target.setAttribute("id" , "smallactive");
            }
        }}>{props.text}</h1>
    )
}