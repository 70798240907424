import React from "react";
import MobileStoreButton from 'react-mobile-store-button';
import { Fade } from "react-reveal";
import QRCode from "react-qr-code";
export default function FooterIphone() {
    return (
        <>
        <div className="footer-iphone">
        <div className="header-main footer-iphone-main">
            <div className="header-contant-img footer-phone"  >
                <Fade bottom>
                <img src= "./images/veliPhone.png"   alt="iphone" className="header-iphone" />
                </Fade>
                <Fade bottom>
                <div className="haeder-contant">
                    <h1 className="header-title">جاهز للبدء؟</h1>
                    <p className="header-text">لا يستغرق الأمر سوى بضع دقائق لتحميل
 التطبيق، للشراء والبيع، وبدء الاستثمار</p>
                    <div className="header-btns">
                    <span className="qrcode-container"><QRCode value=""/></span>
                    <img src="./images/button-play.webp"  style={{width: "195.577px",height: "61.599px" }} alt="google store btn" />
                    <img src="./images/button-app.webp"  style={{width: "195.577px",height: "61.599px" }} alt="app store btn" />
                    </div>
                </div>
                </Fade>
            </div>
        </div>
    </div>

        </>
    )
}

